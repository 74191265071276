import React, { useState } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ReactPlayer from "react-player"
import LazyLoad from "react-lazyload"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Fade } from "react-awesome-reveal"
import IconOne from "../../components/iconOne"
import IconTwo from "../../components/iconTwo"
import IconThree from "../../components/iconThree"
import t from "../../locale"

const IndexPage = ({ pageContent }) => {
  const lang = "en"
  const [videoToPopup, setVideoToPopup] = useState(null)
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpenPackaging, setIsOpenPackaging] = useState(false)
  const [isOpenOthers, setIsOpenOthers] = useState(false)
  const [accordionOpen, setAccordionOpen] = useState(
    pageContent.homePage.icons.map((item, index) => false)
  )

  const heroSliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 9000,
    pauseOnHover: false,
    lazyLoad: true,
  }

  const handleVideoClick = video => {
    setVideoToPopup(video)
  }

  const handlePopupClick = () => {
    setVideoToPopup(null)
  }

  const handleAccordionClick = index => {
    const accordion_array = accordionOpen
    accordion_array[index] = !accordion_array[index]
    setAccordionOpen([...accordion_array])
  }

  const showPackaging = () => {
    ;[...document.getElementsByClassName("packaging-col")].forEach(
      packagingItem => {
        packagingItem.style.display = "block"
      }
    )
    document.getElementById("packaging-btn").style.display = "none"
  }

  const showOthers = () => {
    ;[...document.getElementsByClassName("others-col")].forEach(othersItems => {
      othersItems.style.display = "block"
    })
    document.getElementById("others-btn").style.display = "none"
  }

  const showVideos = () => {
    ;[...document.getElementsByClassName("video-col")].forEach(videosItems => {
      videosItems.style.display = "block"
    })
    document.getElementById("video-btn").style.display = "none"
  }

  const galleryPackaging = pageContent.homePage.packagingDesign.map(
    item => item.imageBig.sourceUrl
  )

  const galleryOthers = pageContent.homePage.others.map(
    item => item.imageBig.sourceUrl
  )

  return (
    <Layout lang={lang} translationPL="/pl/" translationFO="/">
      <SEO
        title={
          pageContent.seo.title ? pageContent.seo.title : pageContent.title
        }
        description={pageContent.seo.metaDesc && pageContent.seo.metaDesc}
        image={
          pageContent.seo.opengraphImage &&
          pageContent.seo.opengraphImage.localFile.publicURL
        }
      />
      <section className="home-hero">
        <div className="home-hero__slider-container slider-container">
          <Slider {...heroSliderSettings}>
            {pageContent.homePage.mainSlider.map((item, index) => (
              <div key={index}>
                <div
                  className="single-slide"
                  style={{ backgroundImage: `url(${item.image.sourceUrl})` }}
                >
                  <div className="single-slide__video">
                    <ReactPlayer
                      url={item.video}
                      width="100%"
                      heigth="100%"
                      playing={true}
                      controls={false}
                      muted={true}
                      playsinline
                      loop={true}
                    />
                  </div>
                  <div className="single-slide__wrapper">
                    <h2
                      className="single-slide__title"
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                    <Link
                      to={item.link}
                      className="btn btn--white btn--lines single-slide__button"
                    >
                      See more
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <AnchorLink
            to="#about-us"
            className="slider-container__arrows d-none d-md-block"
          >
            <img
              src={require("../../assets/images/ico-arrow-down.png")}
              alt="See more"
            />
          </AnchorLink>
        </div>
      </section>
      <span className="anchor" id="about-us"></span>
      <section className="home-about">
        <div className="container">
          <Fade delay={150} direction={"bottom"} triggerOnce>
            <h2 className="with-line home-about__title d-none d-md-block">
              About us
            </h2>
          </Fade>
          <div className="row no-gutters justify-content-between">
            {pageContent.homePage.icons.map((item, index) => {
              return (
                <div className="col-md-4" key={index}>
                  <div className="single-item">
                    {index === 0 ? (
                      <span className="single-item__icon d-none d-md-block">
                        <IconOne />
                      </span>
                    ) : index === 1 ? (
                      <span className="single-item__icon d-none d-md-block">
                        <IconTwo />
                      </span>
                    ) : (
                      <span className="single-item__icon d-none d-md-block">
                        <IconThree />
                      </span>
                    )}
                    <p className="single-item__title d-none d-md-block">
                      {item.title}
                    </p>
                    <p className="single-item__desc d-none d-md-block">
                      {item.desc}
                    </p>
                    <div
                      className={`home-about__accordion d-md-none${
                        accordionOpen[index] ? " is-open" : ""
                      }`}
                    >
                      <button
                        type="button"
                        className="home-about__accordion__header"
                        onClick={() => handleAccordionClick(index)}
                      >
                        <img
                          src={require("../../assets/images/accordion-icon.svg")}
                          alt=""
                          className="home-about__accordion__header-icon"
                        />
                        <span className="single-item__title">{item.title}</span>
                      </button>
                      <div
                        className={`home-about__accordion__content${
                          accordionOpen[index] ? " is-open" : ""
                        }`}
                      >
                        <p className="single-item__desc">{item.desc}</p>
                        {index === 0 ? (
                          <IconOne />
                        ) : index === 1 ? (
                          <IconTwo />
                        ) : (
                          <IconThree />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <a href="#portfolio" className="slider-container__arrows d-md-none">
            <img
              src={require("../../assets/images/ico-arrow-down.png")}
              alt="Síggj meira"
            />
          </a>
          <div className="center-button m-t-60 d-none d-md-flex">
            <Fade delay={450} triggerOnce>
              <Link to={t("/about-us/", lang)} className="btn d-block">
                Read more
              </Link>
            </Fade>
          </div>
        </div>
      </section>
      <span className="anchor" id="portfolio"></span>
      <section className="home-portfolio">
        <div className="container">
          <Fade direction="right" delay={150} triggerOnce>
            <h2 className="with-line home-portfolio__title">Portfolio</h2>
          </Fade>
          <Fade direction="left" delay={150} triggerOnce>
            <ul className="home-portfolio__menu menu">
              <li className="menu__item">
                <span className="divider"></span>
              </li>
              <li className="menu__item">
                <AnchorLink to="#campaigns">Campaigns</AnchorLink>
              </li>
              <li className="menu__item">
                <span className="divider"></span>
              </li>
              <li className="menu__item">
                <AnchorLink to="#video">Video</AnchorLink>
              </li>
              <li className="menu__item">
                <span className="divider"></span>
              </li>
              <li className="menu__item">
                <AnchorLink to="#packaging-design">Packaging design</AnchorLink>
              </li>
              <li className="menu__item">
                <span className="divider"></span>
              </li>
              <li className="menu__item">
                <AnchorLink to="#logos">Logos</AnchorLink>
              </li>
              <li className="menu__item">
                <span className="divider"></span>
              </li>
              <li className="menu__item">
                <AnchorLink to="#websites">Websites</AnchorLink>
              </li>
              <li className="menu__item">
                <span className="divider"></span>
              </li>
              <li className="menu__item">
                <AnchorLink to="#others">Others</AnchorLink>
              </li>
              <li className="menu__item">
                <span className="divider"></span>
              </li>
            </ul>
          </Fade>
          <span className="anchor" id="campaigns"></span>
          <div className="single-portfolio-container">
            <Fade delay={150} triggerOnce direction="left">
              <div className="header-wrapper">
                <h3>Campaigns</h3>
              </div>
            </Fade>
            <Fade triggerOnce delay={150}>
              <div className="slider-container">
                <Slider {...heroSliderSettings}>
                  {pageContent.homePage.campaignSlider.map((item, index) => (
                    <div key={index}>
                      <div className="single-slide">
                        <img
                          src={item.image.sourceUrl}
                          alt=""
                          className="img-fluid single-slide__img"
                        />

                        <div className="single-slide__content">
                          <h3 className="single-slide__title">{item.title}</h3>
                          <Link
                            to={item.link}
                            className="btn--white single-slide__button"
                          >
                            See more
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </Fade>
          </div>
          <span className="anchor" id="video"></span>
          <div className="single-portfolio-container">
            <Fade delay={150} triggerOnce direction="right">
              <div className="header-wrapper">
                <h3>Video</h3>
              </div>
            </Fade>
            <div className="row">
              {pageContent.homePage.videos.map((video, index) => (
                <div className="col-md-6 video-col" key={index}>
                  <Fade triggerOnce delay={150}>
                    <div
                      className="video-container"
                      onClick={() => handleVideoClick(video)}
                      role="presentation"
                    >
                      <img
                        src={video?.thumbnail?.sourceUrl}
                        alt=""
                        className="img-fluid video-container__thumbnail"
                      />
                      <img
                        src={require("../../assets/images/ico-play-button.png")}
                        alt=""
                        className="video-container__play-button"
                      />
                    </div>
                  </Fade>
                </div>
              ))}
            </div>
            <div className="center-button m-t-40">
              <button
                id="video-btn"
                className="btn btn--big"
                onClick={showVideos}
              >
                See more
              </button>
            </div>
          </div>
          <span className="anchor" id="packaging-design"></span>
          <div className="single-portfolio-container">
            <Fade delay={150} triggerOnce direction="left">
              <div className="header-wrapper">
                <h3>Packaging design</h3>
              </div>
            </Fade>
            <div className="row">
              {pageContent.homePage.packagingDesign.map((item, index) => (
                <div
                  className={`col-sm-${item.size} packaging-col`}
                  key={index}
                >
                  <Fade triggerOnce delay={150}>
                    <div className="single-item">
                      <img
                        src={item.imageThumbnail.sourceUrl}
                        alt=""
                        className="img-fluid"
                        onClick={e => {
                          e.preventDefault()
                          setIsOpenPackaging(true)
                          setPhotoIndex(index)
                        }}
                        role="presentation"
                      />
                    </div>
                  </Fade>
                </div>
              ))}
            </div>
            <div className="center-button m-t-40">
              <button
                id="packaging-btn"
                className={`btn btn--big`}
                onClick={showPackaging}
              >
                See more
              </button>
            </div>
          </div>
          <span className="anchor" id="logos"></span>
          <div className="single-portfolio-container">
            <Fade delay={150} triggerOnce direction="right">
              <div className="header-wrapper">
                <h3>Logos</h3>
              </div>
            </Fade>
            <div className="row">
              {pageContent.homePage.logos.map((item, index) => (
                <div className="col-md-3 col-6" key={index}>
                  <Fade triggerOnce delay={150}>
                    <LazyLoad once>
                      <div className="single-item">
                        <Link to={item.link}>
                          <div className="single-logo">
                            <img
                              src={item.imageThumbnail.sourceUrl}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </Link>
                      </div>
                    </LazyLoad>
                  </Fade>
                </div>
              ))}
            </div>
          </div>
          <span className="anchor" id="websites"></span>
          <div className="single-portfolio-container">
            <Fade delay={150} triggerOnce direction="left">
              <div className="header-wrapper">
                <h3>websites</h3>
              </div>
            </Fade>

            <div className="row">
              {pageContent.homePage.websites.map((item, index) => (
                <div className="col-md-6" key={index}>
                  <Fade triggerOnce delay={150}>
                    <LazyLoad once>
                      <Link to={item.link}>
                        <div className="single-item">
                          <img
                            src={item.imageThumbnail.sourceUrl}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </Link>
                    </LazyLoad>
                  </Fade>
                </div>
              ))}
            </div>
          </div>
          <span className="anchor" id="others"></span>
          <div className="single-portfolio-container">
            <Fade delay={150} triggerOnce direction="right">
              <div className="header-wrapper">
                <h3>others</h3>
              </div>
            </Fade>

            <div className="row">
              {pageContent.homePage.others.map((item, index) => (
                <div className="col-md-6 others-col" key={index}>
                  <Fade triggerOnce delay={150}>
                    <div className="single-item">
                      <img
                        src={item.imageThumbnail.sourceUrl}
                        alt=""
                        className="img-fluid"
                        onClick={e => {
                          e.preventDefault()
                          setIsOpenOthers(true)
                          setPhotoIndex(index)
                        }}
                        role="presentation"
                      />
                    </div>
                  </Fade>
                </div>
              ))}
            </div>
            <div className="center-button m-t-40">
              <button
                id="others-btn"
                className="btn btn--big"
                onClick={showOthers}
              >
                See more
              </button>
            </div>
          </div>
        </div>
      </section>
      {videoToPopup != null && (
        <>
          <div className="video-modal">
            <div className="video-container">
              <div className="video-container__video">
                <ReactPlayer
                  url={videoToPopup.link}
                  width="100%"
                  height="100%"
                  playing={videoToPopup}
                  controls={true}
                  muted={false}
                />
              </div>
            </div>
          </div>
          <div
            className="video-modal__overlay"
            onClick={handlePopupClick}
            role="presentation"
          ></div>
        </>
      )}
      {isOpenPackaging && (
        <Lightbox
          mainSrc={galleryPackaging[photoIndex]}
          nextSrc={galleryPackaging[(photoIndex + 1) % galleryPackaging.length]}
          prevSrc={
            galleryPackaging[
              (photoIndex + galleryPackaging.length - 1) %
                galleryPackaging.length
            ]
          }
          onCloseRequest={() => setIsOpenPackaging(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + galleryPackaging.length - 1) %
                galleryPackaging.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % galleryPackaging.length)
          }
        />
      )}
      {isOpenOthers && (
        <Lightbox
          mainSrc={galleryOthers[photoIndex]}
          nextSrc={galleryOthers[(photoIndex + 1) % galleryOthers.length]}
          prevSrc={
            galleryOthers[
              (photoIndex + galleryOthers.length - 1) % galleryOthers.length
            ]
          }
          onCloseRequest={() => setIsOpenOthers(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + galleryOthers.length - 1) % galleryOthers.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % galleryOthers.length)
          }
        />
      )}
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        pageContent: wpPage(id: { eq: "cG9zdDoxNTc=" }) {
          seo {
            title
            metaDesc
            opengraphImage {
              localFile {
                publicURL
              }
            }
          }
          homePage {
            mainSlider {
              fieldGroupName
              image {
                sourceUrl
              }
              link
              title
              video
            }
            icons {
              desc
              icon {
                sourceUrl
              }
              title
            }
            campaignSlider {
              link
              title
              image {
                sourceUrl
              }
            }
            logos {
              link
              imageThumbnail {
                sourceUrl
              }
            }
            packagingDesign {
              size
              imageThumbnail {
                sourceUrl
              }
              imageBig {
                sourceUrl
              }
            }
            videos {
              link
              thumbnail {
                sourceUrl
              }
            }
            others {
              imageBig {
                sourceUrl
              }
              imageThumbnail {
                sourceUrl
              }
            }
            websites {
              link
              imageThumbnail {
                sourceUrl
              }
            }
          }
        }
      }
    `}
    render={data => <IndexPage {...data} />}
  />
)
